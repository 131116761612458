"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _operation = require("@/api/operation");
var _auth = require("@/utils/auth");
var state = {
  brandList: [],
  brandListAll: []
};
var mutations = {
  SET_BRAND_LIST: function SET_BRAND_LIST(state, brandList) {
    state.brandList = brandList;
  },
  SET_BRAND_LIST_ALL: function SET_BRAND_LIST_ALL(state, brandList) {
    state.brandListAll = brandList;
  }
};
var actions = {
  // get brandList
  getBrandList: function getBrandList(_ref) {
    var commit = _ref.commit,
      _ref$otherFlag = _ref.otherFlag,
      otherFlag = _ref$otherFlag === void 0 ? 0 : _ref$otherFlag;
    return new Promise(function (resolve, reject) {
      (0, _operation.brandList)({
        token: (0, _auth.getToken)(),
        otherFlag: otherFlag
      }).then(function (response) {
        var code = response.code,
          data = response.data;
        if (code !== 0 || !data) {
          reject('Failed to mount application list');
        }
        commit('SET_BRAND_LIST', data.list);
        resolve(data);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  getBrandListAll: function getBrandListAll(_ref2) {
    var commit = _ref2.commit,
      _ref2$otherFlag = _ref2.otherFlag,
      otherFlag = _ref2$otherFlag === void 0 ? 1 : _ref2$otherFlag;
    return new Promise(function (resolve, reject) {
      (0, _operation.brandList)({
        token: (0, _auth.getToken)(),
        otherFlag: otherFlag
      }).then(function (response) {
        var code = response.code,
          data = response.data;
        if (code !== 0 || !data) {
          reject('Failed to mount application list');
        }
        commit('SET_BRAND_LIST_ALL', data.list);
        resolve(data);
      }).catch(function (error) {
        reject(error);
      });
    });
  }
};
var _default = exports.default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};