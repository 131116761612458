"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var getters = {
  sidebar: function sidebar(state) {
    return state.app.sidebar;
  },
  language: function language(state) {
    return state.app.language;
  },
  size: function size(state) {
    return state.app.size;
  },
  device: function device(state) {
    return state.app.device;
  },
  visitedViews: function visitedViews(state) {
    return state.tagsView.visitedViews;
  },
  cachedViews: function cachedViews(state) {
    return state.tagsView.cachedViews;
  },
  token: function token(state) {
    return state.user.token;
  },
  avatar: function avatar(state) {
    return state.user.avatar;
  },
  countryCodes: function countryCodes(state) {
    return state.user.countryCodes;
  },
  name: function name(state) {
    return state.user.name;
  },
  introduction: function introduction(state) {
    return state.user.introduction;
  },
  roles: function roles(state) {
    return state.user.roles;
  },
  brandList: function brandList(state) {
    return state.system.brandList;
  },
  brandListAll: function brandListAll(state) {
    return state.system.brandListAll;
  },
  isBindGoogleAuth: function isBindGoogleAuth(state) {
    return state.user.isBindGoogleAuth;
  },
  // 是否需要开启谷歌验证
  permission_routes: function permission_routes(state) {
    return state.permission.routes;
  },
  errorLogs: function errorLogs(state) {
    return state.errorLog.logs;
  },
  isLock: function isLock(state) {
    return state.user.isLock;
  },
  lockPasswd: function lockPasswd(state) {
    return state.user.lockPasswd;
  },
  messageNimActionType: function messageNimActionType(state) {
    return state.chatNim.messageActionType;
  },
  totalNimUnreadCount: function totalNimUnreadCount(state) {
    return state.chatNim.totalUnreadCount;
  },
  remoteNimAccount: function remoteNimAccount(state) {
    return state.chatNim.remoteAccount;
  },
  messageNimList: function messageNimList(state) {
    return state.chatNim.messageList;
  },
  historyMessagesNim: function historyMessagesNim(state) {
    return state.chatNim.historyMessages;
  },
  messageLoading: function messageLoading(state) {
    return state.chatNim.messageLoading;
  }
};
var _default = exports.default = getters;